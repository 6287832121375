<template>
  <div style="width: 100%;">
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;min-height: 35rem">
      <el-table v-loading="loading" :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="80"></el-table-column>
        <el-table-column prop="order_no" label="订单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="goods_title" label="商品" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fee" label="金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="diy_form" :formatter="diyFormShow" label="表单" show-overflow-tooltip></el-table-column>
        <el-table-column prop="u_nickname" label="购买人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="from_member_nickname" label="直推人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invite_1_award" label="直推奖励" show-overflow-tooltip></el-table-column>
        <el-table-column prop="from_2_member_nickname" label="间推人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="invite_2_award" label="间推奖励" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" :formatter="statusFormatter" label="状态" show-overflow-tooltip></el-table-column>
        <el-table-column prop="pay_time" label="支付时间" show-overflow-tooltip>
          <template #default="s">{{ s.row.pay_time | date }}</template>
        </el-table-column>
        <el-table-column prop="finished_time" label="完成时间" show-overflow-tooltip>
          <template #default="s">{{ s.row.finished_time | date }}</template>
        </el-table-column>
        <el-table-column label="创建时间" show-overflow-tooltip>
          <template #default="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "list",
  components: {},
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      loading: false,
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    diyFormShow(item){
      let a = JSON.parse(item.diy_form);
      let str = "";
      a.forEach(v=>{
        str += `${v.title}:${v.value};\n`
      })
      return str
    },
    statusFormatter(item) {
      let str = "";
      switch (parseInt(item.status)) {
        case 1:
          str = "未支付";
          break;
        case 2:
          str = "已支付";
          break
        case 3:
          str = "已结束";
          break;
      }
      return str;
    },
    pageChange(e) {
      this.page = e;
      this.loadList();
    },
    loadList() {
      this.loading = true;
      this.$api.plugin.shop.order({
        page: this.page,
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    }
  }
}
</script>

<style scoped>

</style>